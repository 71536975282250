export const CONFIG = {
  apiKey: "AIzaSyD3o1Ga_UL399CJroRNquI0ZqWuFt5T9Mg",
  authDomain: "football-club-6caba.firebaseapp.com",
  projectId: "football-club-6caba",
  storageBucket: "football-club-6caba.appspot.com",
  messagingSenderId: "272954012271",
  appId: "1:272954012271:web:c4fba5e05e3bc008c1939a",
  measurementId: "G-9T4H3FZM19",
  databaseURL: "",
};
